'use client';
import Image from 'next/image';
import { Exo_2, Inter, Onest } from 'next/font/google';
import { Button } from '@/components/ui/button';
import { cn, copyToClipboard } from '@/lib/utils';
import { FaLinkedin } from 'react-icons/fa';
import FeaturesTabs from './featuresTabs';
import { Separator } from './ui/separator';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import VideoEmbed from './videoEmbed';

const headFont = Onest({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

const bodyFont = Inter({
  weight: ['300', '400'],
  subsets: ['latin'],
});

const logoFont = Exo_2({ weight: ['300', '400', '700'], subsets: ['latin'] });

interface IMainPageProps {
  numPredictions: number;
}

const MainPage: React.FunctionComponent<IMainPageProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the target is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <div className="flex flex-col items-center mx-auto max-w-5xl">
      {/* Hero */}
      <section className="w-full text-center pointer-events-none absolute top-0 h-[400px] sm:h-[500px] md:h-[600px]">
        <div
          className={cn(
            headFont.className,
            'w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl sm:text-4xl lg:text-5xl xl:text-6xl !leading-tight font-medium text-center bg-clip-text text-transparent bg-gradient-to-br from-foreground to-[#DFE175] bg-opacity-50'
          )}
        >
          {/* <Banner text={<p>News: The <strong>FASHN API is now live!</strong> Access it through your profile settings.</p>} /> */}
          <span
            className={cn(logoFont.className, 'font-semibold text-foreground')}
          >
            FASHN<span className="font-light">AI</span>{' '}
          </span>
          is Building Next Generation <br />
          Visual AI for Fashion
        </div>
      </section>

      <section
        className={cn(
          'w-full flex flex-col gap-10 items-center justify-center mx-auto py-10 md:pb-20 max-w-4xl text-foreground/80 px-4',
          bodyFont.className
        )}
      >
        <SectionBadge title={'About'} />
        <p
          className={cn(
            'max-w-3xl',
            'text-center text-lg sm:text-2xl  font-light !leading-8'
          )}
        >
          FASHN is a self-funded, AI-first company focused on researching
          image-based generative models tailored for the fashion industry.
        </p>
        <div className="flex flex-col gap-4">
          <div
            className={cn(
              'tracking-widest text-sm text-foreground/50',
              headFont.className
            )}
          >
            —TEAM
          </div>
          <div className="flex flex-col md:flex-row gap-10 md:gap-20 text-lg">
            <TeamMember
              name={'Aya Bochman'}
              title={'Co-Founder'}
              role={'SWE'}
              linkedIn="https://www.linkedin.com/in/aya-bochman/"
              imgSrc="/aya.png"
            />
            <TeamMember
              name={'Dan Bochman'}
              title={'Co-Founder'}
              role={'AI/ML'}
              linkedIn="https://www.linkedin.com/in/danbochman/"
              imgSrc="/dan.png"
            />
          </div>
        </div>
      </section>
      <div className='h-full w-full md:w-[60%] md:h-[60%] pb-20 px-4'>
        <VideoEmbed />
      </div>
      <section
        className={cn(
          'w-full flex flex-col gap-8 py-10 md:py-20 mx-auto max-w-3xl relative px-4 text-lg',
          bodyFont.className
        )}
      >
        <h2 className={cn('text-2xl md:text-4xl', headFont.className)}>
          Our Virtual Try-On Model
        </h2>

        <p className={cn('max-w-md', 'opacity-80 !leading-8')}>
          FASHN has developed a virtual try-on solution built from the ground
          up, carefully designed to meet the high standards brands expect when
          showcasing their collections.
        </p>
        <p className="text-foreground z-40">
          {' '}
          The solution is available for commercial use <br />
          through the{' '}
          <Link href="/products/web-app" className="underline">
            FASHN web app
          </Link>{' '}
          or{' '}
          <Link href="/products/api" className="underline">
            API
          </Link>
          .
        </p>

        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={isVisible ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.9, ease: 'easeOut', delay: 0.2 }}
          className="absolute right-0 top-0 z-0"
        >
          <div className="relative">
            <Image
              src={'/model-sil.png'}
              alt={'Model Silhouette'}
              width={500}
              height={600}
            />

            <div className="absolute bottom-0 w-full bg-gradient-to-t from-background to-transparent h-24 z-10"></div>
          </div>
        </motion.div>
      </section>
      <section
        className={cn(
          'w-full flex-col gap-10 items-center justify-center mx-auto pt-20 pb-20 max-w-5xl text-foreground/80 hidden lg:flex px-4',
          bodyFont.className
        )}
      >
        <SectionBadge title={'Features'} />
        <FeaturesTabs />
      </section>
      <Separator
        className="hidden lg:block"
        style={{
          background:
            'linear-gradient(90deg, #1a1a1a 0%, rgba(255, 255, 255, 0) 0%, #8f8f8faa 50%, #1a1a1a 100%)',
        }}
      />
      <section
        className={cn(
          'w-full flex flex-col gap-10 items-center justify-center mx-auto py-20 max-w-4xl text-foreground/80 px-4 z-20',
          bodyFont.className
        )}
      >
        <SectionBadge title={'Contact'} />
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <a
            href="https://discord.gg/MCs39Gf4yn"
            target="_blank"
            aria-label="join discord"
          >
            <Button>Join Our Community on Discord</Button>
          </a>
          ·
          <span className="text-center">
            For inquiries contact us at{' '}
            <Button
              className="p-0 h-fit"
              variant={'ghost'}
              onClick={() => copyToClipboard('info@fashn.ai')}
            >
              info@fashn.ai
            </Button>
          </span>
        </div>
      </section>
    </div>
  );
};

export default MainPage;

const SectionBadge = ({ title }: { title: string }) => {
  return (
    <div
      className={cn(
        headFont.className,
        'uppercase px-6 py-2 bg-special w-fit rounded-full text-xs tracking-widest text-primary'
      )}
    >
      {title}
    </div>
  );
};

const TeamMember = ({
  title,
  role,
  name,
  imgSrc,
  linkedIn,
}: {
  title: string;
  role: string;
  name: string;
  imgSrc: string;
  linkedIn: string;
}) => {
  return (
    <div className="flex items-start gap-4">
      <div className="relative w-14 h-14">
        <Image src={imgSrc} alt={name} className="rounded-lg" fill />
      </div>
      <div className="flex flex-col">
        <span className="text-xs">
          {title} | {role}
        </span>
        <p className="flex items-center gap-2">
          {name}{' '}
          <a
            href={linkedIn}
            target="_blank"
            className="hover:translate-y-[-2px] transition-all"
          >
            <FaLinkedin />
          </a>
        </p>
      </div>
    </div>
  );
};
