import * as React from 'react';



const VideoEmbed: React.FunctionComponent = () => {
    return <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
        <iframe
            src={`https://www.youtube.com/embed/sFtciwdGERc`}
            title="FASHN demo"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
            }}
        />
    </div>
};

export default VideoEmbed;
